// API Configuration
export const API_URL = process.env.REACT_APP_API_URL || '/api';

// Environment Configuration
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

// Feature Flags
export const FEATURES = {
  EMAIL_NOTIFICATIONS: true,
  CSV_EXPORT: true,
  ZOOM_INTEGRATION: true,
  SIGNAL_CHAT: true
};

// Application Settings
export const APP_SETTINGS = {
  SITE_NAME: 'Prophetic Roundtable',
  CONTACT_EMAIL: 'propheticroundtable7@gmail.com',
  DEFAULT_PAGE_SIZE: 10,
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
};

// API Endpoints
export const ENDPOINTS = {
  JOIN_REQUEST: `${API_URL}/submit-join-request`,
  HEALTH_CHECK: `${API_URL}/health`,
  TEST: `${API_URL}/test`,
  BOOKS: `${API_URL}/books`
};

// Export all configurations
const config = {
  API_URL,
  IS_PRODUCTION,
  FEATURES,
  APP_SETTINGS,
  ENDPOINTS
};

export default config; 