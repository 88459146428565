import React, { useState } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './VideoSlider.css';

const VideoSlider = () => {
  const [index, setIndex] = useState(0);

  const videos = [
    {
      id: 'S2KMkqQtA-c',
      title: 'Prophetic Roundtable Ministries - Introduction'
    },
    {
      id: '4Zq4VfjaV7E',
      title: 'Prophetic Insights'
    },
    {
      id: 'lN73wiLAeaM',
      title: 'Community Testimonies'
    },
    {
      id: 'XdQqlCivxhs',
      title: 'Ministry Vision'
    }
  ];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="video-slider">
      <Carousel 
        activeIndex={index} 
        onSelect={handleSelect}
        interval={null}
        indicators={true}
        controls={true}
        className="video-carousel"
      >
        {videos.map((video, idx) => (
          <Carousel.Item key={idx}>
            <div className="video-container">
              <iframe
                src={`https://www.youtube.com/embed/${video.id}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Carousel.Caption>
              <h3>{video.title}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      
      <div className="video-navigation">
        {videos.map((video, idx) => (
          <Button 
            key={idx} 
            variant={idx === index ? "primary" : "outline-primary"}
            onClick={() => setIndex(idx)}
            className="video-nav-button"
          >
            {idx + 1}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default VideoSlider; 