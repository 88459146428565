import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ENDPOINTS, APP_SETTINGS } from '../../config';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!formData.firstName.trim() || !formData.lastName.trim()) {
      setShowError(true);
      setErrorMessage('Please enter your full name');
      return;
    }
    
    if (!formData.email.trim()) {
      setShowError(true);
      setErrorMessage('Please enter your email address');
      return;
    }
    
    if (!formData.subject.trim()) {
      setShowError(true);
      setErrorMessage('Please enter a subject');
      return;
    }
    
    if (!formData.message.trim()) {
      setShowError(true);
      setErrorMessage('Please enter your message');
      return;
    }
    
    // Reset error state
    setShowError(false);
    setErrorMessage('');
    setIsSubmitting(true);
    
    try {
      // Send data to backend API using configured endpoint
      const response = await fetch(ENDPOINTS.JOIN_REQUEST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        body: JSON.stringify({
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          type: 'contact'
        }),
        mode: 'cors',
        credentials: 'omit'
      });
      
      let responseData;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        responseData = await response.json();
      } else {
        const text = await response.text();
        responseData = { message: text };
      }
      
      if (!response.ok) {
        throw new Error(responseData.message || `Server error: ${response.status} ${response.statusText}`);
      }
      
      // Show success message
      setShowSuccess(true);
      
      // Reset form after success
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
      });
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage(error.message || 'There was a problem sending your message. Please try again later.');
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">Contact Us</h1>
        <Row>
          <Col lg={8} className="mx-auto">
            <p className="lead mb-4">
              We'd love to hear from you. Please fill out the form below.
            </p>
            
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      placeholder="Enter first name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      placeholder="Enter last name"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control 
                  type="text" 
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  placeholder="Enter subject"
                  required
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                  as="textarea" 
                  rows={5}
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Enter your message"
                  required
                />
              </Form.Group>
              
              {showError && errorMessage && (
                <div className="error-message show">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                  {errorMessage}
                </div>
              )}
              
              <div className={`success-message ${showSuccess ? 'show' : ''}`}>
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Thank you for your message! We'll get back to you soon.
              </div>
              
              <Button 
                variant="primary" 
                type="submit" 
                className="w-100"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Sending...
                  </>
                ) : 'Send Message'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact; 