import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="mb-4">
          <Col lg={4} md={6} className="mb-4 mb-md-0">
            <h3 className="footer-title">Prophetic Roundtable Ministries</h3>
            <p>Everyone has a voice at the table</p>
          </Col>
          
          <Col lg={2} md={6} className="mb-4 mb-md-0">
            <h5 className="footer-title">About</h5>
            <ul className="footer-links">
              <li><Link to="/about/team">Team</Link></li>
            </ul>
          </Col>
          
          <Col lg={2} md={6} className="mb-4 mb-md-0">
            <h5 className="footer-title">Questions?</h5>
            <ul className="footer-links">
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </Col>
          
          <Col lg={4} md={6} className="mb-4 mb-md-0">
            <h5 className="footer-title">Social</h5>
            <div className="social-icons">
              <a href="https://www.facebook.com/profile.php?id=61562378415394" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="http://www.youtube.com/@propheticroundtable" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="https://x.com/prophetic3633" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </div>
          </Col>
        </Row>
        
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Prophetic Roundtable Ministries. All rights reserved.</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer; 