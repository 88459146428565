const MEDIUM_RSS_URL = 'https://medium.com/feed/@propheticroundtable7';
const CACHE_KEY = 'medium_posts_cache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const extractImageUrl = (content) => {
  const imgRegex = /<img[^>]+src="([^">]+)"/;
  const match = content.match(imgRegex);
  return match ? match[1] : null;
};

const extractExcerpt = (content) => {
  // Create a temporary div to parse HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;
  
  // Get text content and remove extra whitespace
  const plainText = tempDiv.textContent || tempDiv.innerText || '';
  const trimmedText = plainText.replace(/\s+/g, ' ').trim();
  
  // Return first 200 characters with ellipsis
  return trimmedText.substring(0, 200) + '...';
};

const getCachedPosts = () => {
  const cached = localStorage.getItem(CACHE_KEY);
  if (cached) {
    const { posts, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return posts;
    }
  }
  return null;
};

const cachePosts = (posts) => {
  localStorage.setItem(CACHE_KEY, JSON.stringify({
    posts,
    timestamp: Date.now()
  }));
};

export const fetchMediumPosts = async () => {
  try {
    // Check cache first
    const cached = getCachedPosts();
    if (cached) {
      return cached;
    }

    // Fetch from Medium RSS feed
    const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(MEDIUM_RSS_URL)}`);
    const data = await response.json();

    if (data.status === 'ok') {
      const posts = data.items.map(item => ({
        title: item.title,
        date: new Date(item.pubDate).toLocaleDateString(),
        author: item.author,
        excerpt: extractExcerpt(item.content),
        image: item.thumbnail || extractImageUrl(item.content),
        category: 'MINISTRY', // You might want to customize this
        tags: item.categories || [],
        content: item.content,
        link: item.link,
        guid: item.guid
      }));

      // Cache the posts
      cachePosts(posts);
      return posts;
    }
    
    throw new Error('Failed to fetch Medium posts');
  } catch (error) {
    console.error('Error fetching Medium posts:', error);
    // Return cached posts as fallback if available
    return getCachedPosts() || [];
  }
};

export const clearMediumPostsCache = () => {
  localStorage.removeItem(CACHE_KEY);
  console.log('Medium posts cache cleared');
}; 