import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoSlider from './VideoSlider';
import './About.css';

const About = () => {
  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">About Us</h1>
        <Row>
          <Col lg={8} className="mx-auto">
            <p className="lead">
              Prophetic Roundtable Ministries (PRM) exists to train and encourage people in prophetic ministry within the context of community.
            </p>
            <p className="lead">
            We believe that everyone has a voice at the table, therefore we provide various expressions of community and fellowship, such as Zoom Groups, Local Meetups, and Chat Groups.

            </p>
            
            <div className="about-video-section">
              <h2 className="text-center mb-4">Our Core Values</h2>
              <VideoSlider />
              <p className="text-center mt-3">
                Watch these videos to learn more about our mission, vision, and community impact.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About; 