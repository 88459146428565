import React, { useState, useEffect } from 'react';
import './Blog.css';
import { fetchMediumPosts, clearMediumPostsCache } from '../../services/mediumService';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const loadPosts = async (clearCache = false) => {
    try {
      setLoading(true);
      if (clearCache) {
        clearMediumPostsCache();
        setRefreshing(true);
      }
      const mediumPosts = await fetchMediumPosts();
      setPosts(mediumPosts);
      setLoading(false);
      setRefreshing(false);
    } catch (err) {
      setError('Failed to load blog posts');
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  const refreshPosts = () => {
    loadPosts(true);
  };

  if (loading && !refreshing) {
    return (
      <section className="section-padding">
        <div className="container">
          <div className="text-center">Loading posts...</div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="section-padding">
        <div className="container">
          <div className="text-center text-danger">{error}</div>
        </div>
      </section>
    );
  }

  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center">
              <h1 
                className="display-4 mb-3 refreshable-header" 
                onClick={refreshPosts}
                style={{ cursor: 'pointer' }}
              >
                Bruce's Blog {refreshing && <small>(Refreshing...)</small>}
              </h1>
              <p className="text-muted">Click the title to refresh posts</p>
            </div>
          </div>
        </div>
        <div className="row">
          {posts.map((post, index) => (
            <div key={post.guid} className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100">
                {post.image && (
                  <img
                    src={post.image}
                    className="card-img-top"
                    alt={post.title}
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">{post.title}</h5>
                  <p className="card-text">{post.excerpt}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">{post.date}</small>
                    <a
                      href={post.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog; 