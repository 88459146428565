import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

// Placeholder portfolio items
const portfolioItems = [
  {
    title: "Community Outreach Program",
    category: "Outreach",
    image: "https://placehold.co/600x400",
    description: "A community-based initiative focused on providing spiritual guidance and support to local neighborhoods."
  },
  {
    title: "Youth Mentorship Initiative",
    category: "Youth",
    image: "https://placehold.co/600x400",
    description: "A program designed to connect young people with spiritual mentors who can guide their faith journey."
  },
  {
    title: "Worship Experience Series",
    category: "Worship",
    image: "https://placehold.co/600x400",
    description: "A collection of worship events designed to create immersive spiritual experiences for participants."
  },
  {
    title: "Biblical Study Resources",
    category: "Education",
    image: "https://placehold.co/600x400",
    description: "Comprehensive study materials developed to deepen understanding of biblical principles and teachings."
  },
  {
    title: "Prayer Network Initiative",
    category: "Prayer",
    image: "https://placehold.co/600x400",
    description: "A structured prayer network connecting believers for focused intercession and spiritual support."
  },
  {
    title: "Leadership Development Program",
    category: "Leadership",
    image: "https://placehold.co/600x400",
    description: "A training program focused on developing spiritual leaders equipped to guide others effectively."
  }
];

const Portfolio = () => {
  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">Portfolio</h1>
        <p className="text-center mb-5">
          Explore our ministry initiatives, projects, and programs designed to foster spiritual growth and community connection.
        </p>
        
        <Row>
          {portfolioItems.map((item, index) => (
            <Col lg={4} md={6} className="mb-4" key={index}>
              <Card className="h-100">
                <Card.Img 
                  variant="top" 
                  src={item.image} 
                  alt={item.title}
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <Card.Body>
                  <div className="card-category">{item.category}</div>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                  <Button variant="outline-primary">Learn More</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Portfolio; 