import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import './Resources.css';

const Resources = () => {
  // Sample book data - you can replace these with your actual books
  const books = [
    {
      title: "Beyond Human: Fully Identified in the New Creation",
      image: "/images/beyond-human.jpg",
      link: "https://www.amazon.com/Beyond-Human-Fully-Identified-Creation/dp/0994433557"
    },
    {
      title: "Limitless: Living as an Overcomer",
      image: "/images/limitless-nancy.jpg",
      link: "https://www.amazon.com/Limitless-Living-Overcomer-Nancy-Coen/dp/1732163863"
    },
    {
      title: "Mystical Union: Stuff They Never Told You About the Finished Work of the Cross",
      image: "/images/mystical-union.jpg",
      link: "https://www.amazon.com/Mystical-Union-Stuff-never-finished/dp/097708261X"
    },
    {
      title: "The Man Who Talked With Angels - Second Edition",
      image: "/images/angels-roland-buck.jpg",
      link: "https://www.amazon.com/Man-Who-Talked-Angels-Second/dp/B0BPWC2KJ6"
    }
  ];

  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">Resources</h1>

        <div className="mb-4">
          <div className="d-flex align-items-center mb-4">
            <FontAwesomeIcon icon={faBook} size="lg" className="me-2 text-primary" />
            <h2 className="mb-0 fs-4">Books</h2>
            <span className="mx-2 subtitle-text small mb-0">|</span>
            <p className="subtitle-text mb-0 small">Some of our favorites</p>
          </div>
        </div>

        <Row className="book-gallery-small justify-content-start g-3">
          {books.map((book, index) => (
            <Col key={index} xs={6} sm={6} md={3} lg={3} className="mb-3">
              <div className="book-card-small">
                <a href={book.link} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={book.image} 
                    alt={book.title}
                    className="book-image"
                  />
                  <div className="book-title small">{book.title}</div>
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Resources; 