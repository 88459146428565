import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './ZoomCommunity.css';

const ZoomCommunity = () => {
  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">Zoom Community</h1>
        <p className="text-center mb-5">
          Connect with our faith community through regular online gatherings, prayer sessions, 
          and interactive discussions.
        </p>
        
        <Row className="justify-content-center">
          <Col lg={10} md={12}>
            <div className="video-container">
              <iframe
                src="https://www.youtube.com/embed/BE2WXwQnqM4"
                title="Zoom Community Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video-frame"
              ></iframe>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col lg={8} md={10} className="text-center">
            <div className="cta-container">
              <h3 className="mb-4">Interested in joining or sitting in on a Zoom call?</h3>
              <Button 
                as={Link} 
                to="/stay-connected" 
                variant="primary" 
                size="lg"
                className="cta-button"
              >
                Connect With Us
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ZoomCommunity; 