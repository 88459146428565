import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import './DarkModeToggle.css';

const DarkModeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  
  return (
    <div className="dark-mode-toggle">
      <input
        type="checkbox"
        id="dark-mode-toggle"
        className="dark-mode-input"
        checked={theme === 'dark'}
        onChange={toggleTheme}
      />
      <label htmlFor="dark-mode-toggle" className="dark-mode-label">
        <div className="dark-mode-slider"></div>
        <span className="dark-mode-icon dark-mode-icon-sun">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#f39c12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="5"></circle>
            <line x1="12" y1="1" x2="12" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="23"></line>
            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
            <line x1="1" y1="12" x2="3" y2="12"></line>
            <line x1="21" y1="12" x2="23" y2="12"></line>
            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
          </svg>
        </span>
        <span className="dark-mode-icon dark-mode-icon-moon">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#f1c40f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        </span>
        <span className="dark-mode-text">
          {theme === 'dark' ? 'Dark Mode' : 'Light Mode'}
        </span>
      </label>
    </div>
  );
};

export default DarkModeToggle; 