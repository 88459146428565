import React from 'react';
import styles from './UnderConstruction.module.css';

const UnderConstruction = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img 
                    src="/images/jc-coding.png" 
                    alt="Under Construction - Developer at Work"
                    className={styles.image}
                />
            </div>
        </div>
    );
};

export default UnderConstruction; 