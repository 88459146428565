import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Placeholder images
const galleryImages = [
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400",
  "https://placehold.co/600x400"
];

const Gallery = () => {
  return (
    <section className="section-padding">
      <Container>
        <h1 className="section-title">Gallery</h1>
        <p className="text-center mb-5">
          Explore our collection of images from past events, gatherings, and ministry activities.
        </p>
        
        <Row>
          {galleryImages.map((image, index) => (
            <Col lg={4} md={6} className="mb-4" key={index}>
              <div className="gallery-item">
                <img 
                  src={image} 
                  alt={`Gallery item ${index + 1}`} 
                  className="img-fluid rounded shadow"
                  style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Gallery; 