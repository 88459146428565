import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

const Home = () => {
  // Use absolute path for the background image
  const heroBackgroundImage = '/images/eating-at-table.jpg';
  
  // Debug the image path
  useEffect(() => {
    console.log('Background image path:', heroBackgroundImage);
  }, [heroBackgroundImage]);

  return (
    <>
      {/* Hero Section with Background Image */}
      <section className="hero">
        <div 
          className="hero-background" 
          style={{ 
            backgroundImage: `url("${heroBackgroundImage}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        ></div>
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="ministry-title">
            <h1 className="hero-title">Prophetic Roundtable</h1>
            <div className="ministry-subtitle">Ministries</div>
          </div>
          <p className="hero-text">
            Everyone has a voice at the table
          </p>
        </div>
      </section>

      {/* YouTube Video Section */}
      <section className="mission-section">
        <Container>
          <div className="video-container">
            <div className="responsive-video">
              <iframe
                src="https://www.youtube.com/embed/izs9wJDHTU0" 
                title="Welcome to Prophetic Roundtable Ministries"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Home; 