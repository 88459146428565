import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import DarkModeToggle from './DarkModeToggle';
import { ThemeContext } from '../../context/ThemeContext';
import './Header.css'; // We'll create this file next

const Header = () => {
  // Use absolute path for the logo
  const [imagePath, setImagePath] = useState('/images/PRM-Logo-Circle.png');
  const { theme } = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  // Debug the image path
  useEffect(() => {
    console.log('Logo image path:', imagePath);
  }, [imagePath]);

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // If scrolling down and navbar is expanded, collapse it
      if (currentScrollY > lastScrollY && expanded) {
        setExpanded(false);
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, expanded]);

  const handleImageError = () => {
    console.log('Image failed to load, trying fallback path');
    // Try the backup location if the original fails
    setImagePath('/PRM-Logo-Circle.png');
  };

  return (
    <Navbar 
      expand="lg" 
      className="custom-navbar py-3" 
      sticky="top"
      expanded={expanded}
      onToggle={(expanded) => setExpanded(expanded)}
    >
      <Container className="position-relative">
        <div className="brand-container">
          <div className="brand-text">
            <div>Prophetic</div>
            <div>Roundtable</div>
            <div>Ministries</div>
          </div>
          <Navbar.Brand as={Link} to="/" className="brand-logo">
            <img 
              src={imagePath} 
              alt="Prophetic Roundtable Ministries" 
              className="brand-logo-img" 
              onError={handleImageError}
            />
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto nav-menu">
            <Nav.Link as={NavLink} to="/" end className="nav-item">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/blog" className="nav-item">Bruce's Blog</Nav.Link>
            <Nav.Link as={NavLink} to="/media" className="nav-item">Media</Nav.Link>
            <Nav.Link as={NavLink} to="/zoom-community" className="nav-item">Zoom Community</Nav.Link>
            <Nav.Link as={NavLink} to="/events" className="nav-item">Events</Nav.Link>
            <Nav.Link as={NavLink} to="/about" className="nav-item">About</Nav.Link>
            <Nav.Link as={NavLink} to="/stay-connected" className="nav-item">Stay Connected</Nav.Link>
            <Nav.Link as={NavLink} to="/resources" className="nav-item">Resources</Nav.Link>
          </Nav>
          <div className="theme-toggle-container">
            <DarkModeToggle />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header; 