import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './context/ThemeContext';

// Layout Components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

// Page Components
import Home from './components/home/Home';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Gallery from './components/gallery/Gallery';
import Portfolio from './components/portfolio/Portfolio';
import Media from './components/media/Media';

// New Page Components (placeholders for now)
import Blog from './components/blog/Blog';
import ZoomCommunity from './components/zoom/ZoomCommunity';
import Events from './components/events/Events';
import StayConnected from './components/connect/StayConnected';
import Resources from './components/resources/Resources';
import BlogPostEditor from './components/blog/BlogPostEditor';
import Login from './components/auth/Login';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/media" element={<Media />} />
              <Route path="/zoom-community" element={<ZoomCommunity />} />
              <Route path="/events" element={<Events />} />
              <Route path="/about" element={<About />} />
              <Route path="/stay-connected" element={<StayConnected />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/gallery" element={<Gallery />} />
              
              {/* Legacy routes kept for backward compatibility */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/login" element={<Login />} />
              <Route path="/blog/new" element={<BlogPostEditor />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
